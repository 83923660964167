<template>
  <div>
    <Row :gutter="5" class="m-b-10">
        <i-col :xs="24" :sm="8" :md="4">
          <Select v-model="query.deviceModel" size="small" clearable placeholder="请选择设备类型">
            <Option v-for="item in deviceModelList" :key="item.id" :value="item.id">{{item.name}}</Option>
          </Select>
        </i-col>
        <i-col :xs="24" :sm="8" :md="4">
          <Select v-model="query.costType" size="small" clearable placeholder="请选择运维任务类型">
            <Option v-for="item in taskItemTypes" :key="item.value" :value="item.value">{{item.name}}</Option>
          </Select>
        </i-col>
        <i-col  :xs="24" :sm="8" :md="6">
            <i-button size="small" type="primary" icon='ios-search'  @click="handlePageNumberChange(1)">搜索</i-button>
            <i-button size="small"  type="success" class="m-l-5" @click="handleCreateServiceItem">新建成本项</i-button>
        </i-col>
    </Row>

    <Table stripe size="small" :data="tableList" :columns="tableColumns"></Table>
    <div class="paging_style">
        <Page size="small" show-total show-elevator :current="query.pageNumber" :page-size="query.pageSize" :total="total" @on-change="handlePageNumberChange"></Page>
    </div>

    <ModifyItem ref="modifyItemCompoent" @add-success="handleReloadPage" @update-success="loadServiceItemData"/>

    <Modal v-model="modalServiceRuleCompanys" :title="serviceRuleTitle" footer-hide width="1000">
      <inventoryItem v-for="(rule,index) in serviceRules" :key="index"  :content="rule"  :editIndex="index" />
    </Modal>
  </div>
</template>

<script>
import ModifyItem from './ModifyItem'
import inventoryItem from './InventoryItem'

import { getServiceItemPage, deleteServiceItem } from '@/api/msp/serviceitem'
import { formatEnable } from '@/utils/tagStatus'

export default {
  components: {
    ModifyItem, inventoryItem
  },
  data () {
    return {
      query: {
        pageSize: 10,
        pageNumber: 1,
        costType: null,
        deviceModel: null,
        publisherId: this.$store.getters.token.userInfo.publisherId
      },

      tableList: [],
      tableColumns: [
        { title: '配置项', key: 'name' },
        // { title: '售卖价格', key: 'price' },
        // { title: '单位', width: 80, align: 'center', key: 'unitName' },
        { title: '设备类型', width: 100, align: 'center', key: 'devicemodelName' },
        { title: '任务类型', width: 100, align: 'center', key: 'costTypeName' },
        {
          title: '默认采购',
          width: 90,
          align: 'center',
          render: (h, params) => {
            return formatEnable(h, params.row.defaultSelect)
          }
        },
        {
          title: '所含单品',
          render: (h, data) => {
            const showlabel = []
            for (let index = 0; index < data.row.skuVOList.length; index++) {
              showlabel.push(h('span', data.row.skuVOList[index].skuName))
              if (index < data.row.skuVOList.length - 1) {
                showlabel.push(h('span', { attrs: { class: 'remark' } }, ' | '))
              }
            }

            if (data.row.skuVOList.length === 0) {
              showlabel.push(h('span', '-'))
            }

            return h('div', showlabel)
          }
        },
        {
          title: '供应商成本配置',
          render: (h, data) => {
            const showlabel = []
            data.row.serviceItemDetailDtoList.forEach(element => {
              showlabel.push(h('span', element.companyName))
              showlabel.push(h('br'))
            })

            return h('a', {
              on: {
                click: () => {
                  this.serviceRuleTitle = data.row.name
                  this.modalServiceRuleCompanys = true
                  this.serviceRules = data.row.serviceItemDetailDtoList.map(item => {
                    item.itemInventoryDtoList = JSON.parse(JSON.stringify(item.inventoryVOList))
                    return item
                  })
                }
              }
            }, showlabel)
          }
        },
        {
          title: '操作',
          width: 150,
          align: 'center',
          render: (h, data) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '10px'
                },
                on: {
                  click: () => {
                    this.handleEdit(data.row)
                  }
                }
              }, '编辑'),
              h('a', {
                style: {
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.handleDelete(data.row.id, data.row.name)
                  }
                }
              }, '删除')
            ])
          }
        }
      ],
      total: 0,

      modalServiceRuleCompanys: false,
      serviceRuleTitle: '',
      serviceRules: []
    }
  },
  created () {
    this.loadServiceItemData()
  },
  methods: {
    loadServiceItemData () {
      getServiceItemPage(this.query).then(res => {
        this.tableList = res.list
        this.total = res.totalRow
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.loadServiceItemData()
    },
    handleEdit (params) {
      this.$refs.modifyItemCompoent.showModal(params)
    },
    handleDelete (itemId, name) {
      this.$Modal.confirm({
        title: '确定删除？',
        content: '<p>确定要删除 ' + name + ' 成本项吗？删除后将不可恢复！</p>',
        loading: true,
        onOk: () => {
          deleteServiceItem({ serviceItemId: itemId }).then(res => {
            if (res && !res.errcode) {
              this.loadServiceItemData()
              this.$Notice.success({ desc: '删除 ' + name + ' 成本项成功。' })
              // 如果左侧正在编辑当前成本项，那么变更为创建状态
              if (this.leftPageType === 'edit' && this.editId === itemId) {
                this.handleCreateServiceItem()
              }
            }
          })
          this.$Modal.remove()
        }
      })
    },
    handleCreateServiceItem () {
      this.$refs.modifyItemCompoent.showModal()
    },
    handleReloadPage () {
      this.handlePageNumberChange(1)
    }
  },
  computed: {
    deviceModelList () { // 设备类型清单
      return this.$store.state.serviceConfig.deviceModelListData
    },
    taskItemTypes () { // 任务类型
      return this.$store.state.serviceConfig.taskItemTypesData
    }
  }
}
</script>
